<template>
  <div class="bok_con">
    <div class="bok_hea">
      <back />
      <p>我的志愿者证</p>
    </div>
    <div v-if="codeShow">
      <div class="main_con">
        <div class="top_pic">
          <div class="left_part">
            <div class="top_icon">
              <img class="left_pic" src="../../assets/images/zhiyuanzheicon1.png" alt srcset />
              <img class="right_pic" src="../../assets/images/zhiyaunzheCard.png" alt />
            </div>
            <div class="peopleMes">
              <div class="wordPart">
                <div class="word">
                  <span style="font-size:12px;font-weight:bold;">姓 名</span>
                  <span style="margin-left:4px;">Name</span>
                  <div>{{userInfo.realName}}</div>
                </div>
                <div class="word" style="margin-left:30px">
                  <span style="font-size:12px;font-weight:bold;">性 别</span>
                  <span style="margin-left:4px;">Sex</span>
                  <div v-if="userInfo.sex==0">女</div>
                  <div v-if="userInfo.sex==1">男</div>
                  <div v-if="userInfo.sex==2">男</div>
                </div>
              </div>
              <div class="wordPart">
                <div class="word">
                  <span style="font-size:12px;font-weight:bold;">身份证号</span>
                  <span style="margin-left:4px;">Identity card</span>
                  <div>{{userInfo.idNumber}}</div>
                </div>
              </div>
              <div class="wordPart">
                <div class="word">
                  <span style="font-size:12px;font-weight:bold;">注册日期</span>
                  <span style="margin-left:4px;">Date of registration</span>
                  <div>{{userInfo.createdTime}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right_part">
            <img :src="userInfo.avatar" v-if="userInfo.avatar" style="object-fit:fill;" alt srcset />
            <img src="../../assets/images/headmoren.png" v-else style="object-fit:fill;" alt srcset />
            <div class="codeBox">
              <div class="qrcode" ref="qrCodeUrl"></div>
              <div class="qrTitle">志愿者二维码</div>
            </div>
          </div>
        </div>
        <div class="bottom_pic">
          <img src="../../assets/images/zhiyuanzheicon1.png" alt srcset />
          <div class="paragraph">
            <div>
              <div>宣讲</div>
              <div class="engT">PREACH</div>
            </div>
            <div>
              <div>友爱</div>
              <div class="engT">LOVE</div>
            </div>
            <div>
              <div>互助</div>
              <div class="engT">HELP</div>
            </div>
            <div>
              <div>温暖</div>
              <div class="engT">WARM</div>
            </div>
          </div>
          <div class="bottomWord">——旌德县新时代文明实践中心——</div>
        </div>
      </div>
    </div>
    <div class="noCard" v-if="!codeShow">
      <img src="../../assets/images/smile.png" alt srcset />
      <div>个人信息填写不完整，暂无志愿者证</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import PersonApi from "@/api/person";
import authApi from "@/api/auth";

export default {
  data() {
    return {
      page: 1,
      size: 18,
      timeList: [],
      loading: false,
      finished: false,
      refreshing: false,
      userInfo: {},
      codeShow: true
    };
  },
  created() {
    this.codeShow = this.$route.query.codeShow
  },
  mounted() {
    this.queryInfo();
    let memberMes = {}
    const { id } = JSON.parse(window.localStorage.getItem("userinfo"));
    memberMes.id = id;
    memberMes.qrType = '1';
    let result = JSON.stringify(memberMes);// 转成JSON对象
    this.creatQrCode(result);
  },
  methods: {
    creatQrCode(result) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: String(result),
        width: 55,
        height: 55,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 查询个人信息
    queryInfo() {
      authApi
        .memberInfomatin()
        .then((res) => {
          if (res.success) {
            this.userInfo = res.data;

            this.queryTimeList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询志愿时长列表
    queryTimeList() {
      const params = {
        page: this.page,
        size: this.size,
        memberId: JSON.parse(window.localStorage.personalInformation).id,
      };
      PersonApi.getTime(params)
        .then((res) => {
          this.loading = false;
          this.refreshing = false;
          let arr = res.data.items;
          if (this.page === 1) {
            this.timeList = [];
          }
          if (arr) {
            this.timeList = this.timeList.concat(arr);
          }
          if (!arr || arr.length < this.size) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 上拉加载
    onLoad() {
      this.page += 1;
      this.queryTimeList();
    },

    // 下拉刷新
    onRefresh() {
      this.page = 1;
      this.queryInfo();
    },
  },
};
</script>

<style lang="less" scoped>
body {
  -webkit-text-size-adjust: none;
}
.bok_con {
  position: relative;
  .bok_hea {
    height: 75px;
    background: url("../../assets/financialBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .scr_top {
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translate(-50%);
      font-weight: bold;
      font-size: 20px;
      color: #fff;
    }
    .scr_btm {
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translate(-50%);
      color: #fff;
      font-size: 15px;
    }
    img {
      width: 25px;
      position: absolute;
      top: 20px;
      left: 7px;
    }
    p {
      position: absolute;
      top: 29px;
      left: 50%;
      transform: translate(-50%);
      color: #fff;
      font-size: 17px;
    }
  }
  .noCard {
    display: flex;
    height: 3rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
  }
  .main_con {
    padding: 30px 6% 0 6%;
    .top_pic {
      height: 4rem;
      display: flex;
      flex-direction: row;
      background-image: url("../../assets/images/zhiyuanzheBac.png");
      background-size: 100%;
      background-repeat: no-repeat;
      .left_part {
        width: 222px;
        float: left;
        .top_icon {
          display: flex;
          align-items: center;
          padding: 15px 0 0 18px;
          .left_pic {
            width: 28px;
            padding-right: 5px;
          }
          .right_pic {
            width: 100px;
          }
        }
        .peopleMes {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 0 51px;
          .wordPart {
            width: 175px;
            height: 33px;
            display: flex;
            flex-direction: row;
            margin-top: 6px;
            .word {
              color: #dc2b19;
              div {
                color: #333333;
              }
            }
          }
        }
      }
      .right_part {
        width: 25%;
        padding: 14px 0 12px 10px;
        img {
          // padding-left: 5px;
          width: 75px;
          height: 81px;
        }
        .codeBox {
          width: 75px;
          height: 70px;
          background: #ffffff;
          display: flex;
          // justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 5px;
          .qrTitle {
            font-size: 8px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
    .bottom_pic {
      height: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 25px;
      background-image: url("../../assets/images/zhiyuanzheBac.png");
      background-size: 100%;
      background-repeat: no-repeat;
      img {
        width: 57px;
        height: 57px;
      }
      .paragraph {
        display: flex;
        justify-content: space-between;
        width: 220px;
        padding: 6px 10px 20px 0px;
        font-size: 20px;
        font-family: FZCuHeiSongS-B-GB;
        font-weight: 400;
        color: #dc2b19;
        .engT {
          font-size: 12px;
          font-family: PingFang SC;
          color: #dc2b19;
          text-align: center;
        }
      }
      .bottomWord {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #dc2b19;
      }
    }
  }
}
</style>
